import * as React from 'react';

import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/layout';
import Seo from '../components/seo';
import Contact from '../components/contact';

function Index() {
  return (
    <Layout>
      <Seo title="Accessible Transportatio - St. Thomas, ON" />
      <div className="bg-white">
        <div className='flex flex-col gap-y-10 lg:flex-row'>
          <div className="lg:absolute lg:inset-0 lg:left-1/2">
            <StaticImage
              src="../images/quality-care-transit-van.png"
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Quality Care Transit"
              className='h-64 w-full bg-gray-50 object-cover sm:h-80 lg:absolute lg:h-full'
            />
          </div>
          <div className="flex flex-col page-content gap-y-5 h-full justify-end lg:w-1/2 lg:pt-28 xl:pt-56">

            <div className="flex flex-col gap-y-2">

              <h1 className="title">Quality Care Transit</h1>
              <h2 className="sub-title">
                Accessible Transportation
              </h2>
              <span className='eyebrow'>St. Thomas, ON</span>
            </div>
            <div className='border-t-2 w-1/2 mx-auto'/>
            <div id="services" className="flex flex-col gap-y-2">
              <p className='heading'>We offer a fully accessible transportation service</p>
            <div className='items'>
              <ul>
                <li>Medical Appointments</li>
                <li>Airport Services</li>
                <li>Event Transportation</li>
                <li>Personal Transportation</li>
                <li>Group Outings</li>
                <li>Light Delivery Service</li>
              </ul>
            </div>
            </div>
            <div id="cta" className='flex flex-col'>
              <p className='cta'>Text or Call us to reserve your transit appointment.</p>
              <div className='flex flex-col'>
              <a className='cta-phone' id="cta-phone" href="tel:519-494-7236">519-494-7236</a>

              <a className='cta-phone' id="cta-email" href="mailto:qualitycaretransit1st@gmail.com ">qualitycaretransit1st@gmail.com</a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </Layout>
  );
}

export default Index;
